import React from 'react';
import './assets/css/common.css';
import {Routes, Route, Navigate} from 'react-router-dom';
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import Accounts from "./pages/accounts";
import Account from "./pages/accounts/[account_id]";
import AccountTrading from "./pages/accounts/trading/[account_id]";
import AccountStats from "./pages/accounts/stats/[account_id]";
import AccountsDemo from "./pages/accounts/demo";
import AccountsLive from "./pages/accounts/live";
import Profile from "./pages/profile";
import TwoFactor from "./pages/profile/two-factor";
import Messages from "./pages/profile/messages";
import ViewMessage from "./pages/profile/messages/[message_id]";
import Agreements from "./pages/profile/agreements";
import Verification from "./pages/profile/verification";
import Index from "./pages/dashboard";
import Wallets from "./pages/funds/wallet";
import Deposit from "./pages/funds/deposit";
import Withdraw from "./pages/funds/withdraw";
import Transfer from "./pages/funds/transfer";
import PaymentDetails from "./pages/funds/details";
import PaymentHistory from "./pages/funds/history";
import Calculator from "./pages/tools/calculator";
import Calendar from "./pages/tools/calendar";
import Platform from "./pages/platform";
import OpenTickets from "./pages/helpdesk/open";
import ClosedTickets from "./pages/helpdesk/closed";
import Ticket from "./pages/helpdesk/ticket";
import TicketView from "./pages/helpdesk/[ticket_id]";
import IB from "./pages/ib";
import IBDashboard from "./pages/ib/dashboard";
import Performance from "./pages/ib/performance";
import Links from "./pages/ib/links";
import Banners from "./pages/ib/banners";
import AccountsCommission from "./pages/ib/reports/accounts-commission";
import ClientsCommission from "./pages/ib/reports/clients-commission";
import CPAPayments from "./pages/ib/reports/cpa-payments";
import Forget from "./pages/auth/forget";
import IBTransactions from "./pages/ib/reports/transactions";
import DetailedCommissionBreakdown from "./pages/ib/reports/detailed-commission-breakdown";
import DepositCallback from "./pages/payment/process/deposit";
import WithdrawCallback from "./pages/payment/process/withdrawal";
import IBLinks from "./pages/links/go/[id]";
import Terminal from "./pages/terminal";
import Vps from "./pages/vps";
import NotFound from "./pages/common/404";
import Logout from "./pages/auth/logout";
import Trading from "./pages/ib/reports/trading";
import CreateVps from "./pages/vps/create";
import SocialTradingProvider from "./pages/social/provider";
import SocialTradingLogin from "./pages/social/login";
import Top from "./pages/social/top";
import NewAccount from "./pages/accounts/new";

function App({pageProps}) {
  return (
      <Routes {...pageProps}>
            <Route path='*' element={<NotFound />} />
            <Route
                path="/"
                element={<Navigate to="/dashboard" replace />}
            />
        <Route path={"login"} element={<Login/>} />
        <Route path={"logout"} element={<Logout/>} />
        <Route path={"register"} element={<Register/>} />
        <Route path={"forget-password"} element={<Forget/>} />
        <Route path={"dashboard"} element={<Index/>} />

        <Route path={"accounts/overview"} element={<Accounts/>} />
        <Route path={"accounts/show/:account_id"} element={<Account/>} />
        <Route path={"accounts/stats/:account_id"} element={<AccountStats/>} />
        <Route path={"accounts/trading/:account_id"} element={<AccountTrading/>} />
        <Route path={"accounts/demo"} element={<AccountsDemo/>} />
        <Route path={"accounts/live"} element={<AccountsLive/>} />
        <Route path={"accounts/new"} element={<NewAccount/>} />

        <Route path={"profile"} element={<Profile/>} />
        <Route path={"profile/two-factor"} element={<TwoFactor/>} />
        <Route path={"profile/messages"} element={<Messages/>} />
        <Route path={"profile/message/:message_id"} element={<ViewMessage/>} />
        <Route path={"profile/agreements"} element={<Agreements/>} />
        <Route path={"profile/verification"} element={<Verification/>} />

        <Route path={"funds/deposit"} element={<Deposit/>} />
        <Route path={"funds/deposit/:account_id"} element={<Deposit/>} />
        <Route path={"funds/wallets"} element={<Wallets/>} />
        <Route path={"funds/withdraw"} element={<Withdraw/>} />
        <Route path={"funds/withdraw/:account_id"} element={<Withdraw/>} />
        <Route path={"funds/transfer"} element={<Transfer/>} />
        <Route path={"funds/transfer/:account_id"} element={<Transfer/>} />
        <Route path={"funds/details"} element={<PaymentDetails/>} />
        <Route path={"funds/history"} element={<PaymentHistory/>} />

        <Route path={"tools/trading-calculator"} element={<Calculator/>} />
        <Route path={"tools/economic-calendar"} element={<Calendar/>} />

        <Route path={"terminal"} element={<Terminal/>} />
        <Route path={"social/provider"} element={<SocialTradingProvider/>} />
        <Route path={"social/login"} element={<SocialTradingLogin/>} />
        <Route path={"social/top10"} element={<Top/>} />

        <Route path={"vps"} element={<Vps/>} />
        <Route path={"vps/create"} element={<CreateVps/>} />

        <Route path={"platform"} element={<Platform/>} />
        <Route path={"helpdesk/ticket"} element={<Ticket/>} />
        <Route path={"helpdesk/ticket/:id"} element={<TicketView/>} />
        <Route path={"helpdesk/open-tickets"} element={<OpenTickets/>} />
        <Route path={"helpdesk/closed-tickets"} element={<ClosedTickets/>} />

        <Route path={"ib"} element={<IB/>} />
        <Route path={"ib/dashboard"} element={<IBDashboard/>} />
        <Route path={"ib/performance"} element={<Performance/>} />
        <Route path={"ib/links"} element={<Links/>} />
        <Route path={"ib/banners"} element={<Banners/>} />
        <Route path={"ib/reports/accounts-commission"} element={<AccountsCommission/>} />
        <Route path={"ib/reports/clients-commission"} element={<ClientsCommission/>} />
        <Route path={"ib/reports/cpa-payments"} element={<CPAPayments/>} />
        <Route path={"ib/detailed-commission-breakdown"} element={<DetailedCommissionBreakdown/>} />
        <Route path={"ib/transactions"} element={<IBTransactions/>} />
        <Route path={"ib/trading/:id"} element={<Trading/>} />

        <Route path={"payment/process/deposit/:type"} element={<DepositCallback/>} />
        <Route path={"payment/process/withdrawal/:type"} element={<WithdrawCallback/>} />

        <Route path={"links/go/:id"} element={<IBLinks/>} />
      </Routes>
  );
}

export default App;
