import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {getIbPublicLinkDetailApi} from "../../../api/api";
import {useDispatch} from "react-redux";
import {setReferral} from "../../../redux/actions/user";

const IBLinks = () => {

    const dispatch = useDispatch();
    const Router = useParams();
    const {id} = Router;

    useEffect(()=>{
        if(id){
            getIbPublicLinkDetail(id);
        }
    },[])

    const getIbPublicLinkDetail = async (id) => {
        await getIbPublicLinkDetailApi(id).then((result)=>{
            dispatch(setReferral({partnerId: result.data.partnerId, referralLinkId: id}))
            window.location.href = result.data.redirectUrl;
        }).catch((error)=>{
            console.log(error)
        })
    }
}

export default IBLinks;