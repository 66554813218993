import React from "react";
import Style from '../assets/css/panel.module.css';
import {useSelector} from "react-redux";
import LiveChat from 'react-livechat';

const AuthLayout = ({children}) => {

    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    return (
        <section className={`${Style.AuthContainer} ${dir === "rtl" ? `${Style.RtlStyle} rtl` : ``}`}>
            <div className={Style.AuthWrapper}>
                {children}
            </div>
            <LiveChat license={15833787} />
        </section>
    )
}

export default AuthLayout;