import PanelLayout from "../../../layout/panel";
import {getUserClosedTicketsApi} from "../../../api/api";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import HelpDeskTabs from "../../../components/helpdesk/tabs";
import Style from "../../../assets/css/panel.module.css";
import HelpDeskIcon from "../../../assets/img/icon/help/life-buoy-02.svg"
import {Button, Table, Tag} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ClosedTickets = () => {

    const navigate = useNavigate();
    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showEmpty, setShowEmpty] = useState(false);

    useEffect(()=>{
        getUserClosedTickets();
        document.title = `${translate('wingo')} - ${translate('menu_closed_tickets')}`;
    },[])

    const getUserClosedTickets = async () => {
        setLoading(true);

        await getUserClosedTicketsApi(token).then((result)=>{
            const data = result.data;

            if(data.length){
                setTickets(data.filter((x)=>x.key = x.id))
                setShowEmpty(false)
            } else {
                setShowEmpty(true)
            }
            setLoading(false);
        }).catch((error)=> {
            console.log(error)
        })
    }

    const columns = [
        {
            key: 'id',
            dataIndex: 'id',
            title: translate('id')
        },
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Title',
            render: (title,_value)=> <Link to={`/helpdesk/ticket/${_value.id}`}>{title}</Link>
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: translate('title'),
            render: (status) => <Tag>{status}</Tag>
        },
        {
            key: 'category',
            dataIndex: 'category',
            title: translate('help_category')
        },
        {
            key: 'createdAt',
            dataIndex: 'createdAt',
            title: translate('account_created_at')
        }
    ]

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_help_dek')}</h1>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <HelpDeskTabs/>

                    {showEmpty ?
                        <div className={Style.HelpDeskEmpty}>
                            <div className="d-flex align-items-center justify-content-center">
                                <img src={HelpDeskIcon} alt=""/>
                            </div>
                            <span>{translate('help_no_ticket')}</span>
                        </div> :
                        <div style={{marginTop: "50px"}}>
                            <Table
                                scroll={{x: 560}}
                                className={Style.HelpDeskTable}
                                loading={loading}
                                columns={columns}
                                dataSource={tickets}
                            />
                        </div>
                    }

                    <div className="d-flex justify-content-end">
                        <Button
                            size="large"
                            shape="round"
                            className="dark-green-button white-color"
                            icon={<PlusOutlined />}
                            onClick={()=> navigate("/helpdesk/ticket")}
                        >
                            {translate('help_new_ticket')}
                        </Button>
                    </div>

                </div>
            </div>
        </PanelLayout>
    )
}

export default ClosedTickets;