import Style from "../../assets/css/panel.module.css";
import { Button, Table, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {Scrollbar, Navigation} from 'swiper/modules';
import "swiper/css";
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../layout/components/windowSize";
import {useSelector} from "react-redux";

const LiveAccounts = ({ liveAccounts, user, loading, scroll }) => {

    const { t } = useTranslation();
    const translate = t;
    const navigate = useNavigate();
    const {device} = useWindowSize();
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");

    const columns = [
        {
            key: 'login',
            title: translate('accounts_login'),
            dataIndex: 'login'
        },
        {
            key: 'type',
            title: translate('account_type'),
            dataIndex: 'type',
            render: (type) => type.title
        },
        {
            key: 'type',
            title: translate('menu_platform'),
            dataIndex: 'type',
            render: (type) => type.platform
        },
        {
            key: 'currency',
            title: translate('funds_currency'),
            dataIndex: 'currency'
        },
        {
            key: 'leverage',
            title: translate('funds_leverage'),
            dataIndex: 'leverage'
        },
        {
            key: 'balance',
            title: translate('funds_balance'),
            dataIndex: 'balance'
        }
    ];

    return (
        <>
            {liveAccounts?.length
                ?
                scroll ?
                    <Swiper
                        scrollbar={{
                            hide: false,
                            draggable: true
                        }}
                        modules={[Scrollbar, Navigation]}
                        slidesPerView={2}
                        navigation={device !== "mobile"}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            }
                        }}
                    >
                        {liveAccounts.map((Account, Index) => (
                            <SwiperSlide key={Index}>
                                <div className={`${Style.AccountsOverview} ${Style.AccountsOverviewBorder}`}>
                                    <Table
                                        scroll={{x: 560}}
                                        loading={loading}
                                        dataSource={[Account]}
                                        columns={columns}
                                        pagination={false}
                                        className="swiper-no-swiping"
                                    />
                                    <div className={Style.AccountsOverviewBox}>
                                        <div className="d-md-flex justify-content-between">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <span>{translate('client_name')}</span>
                                                    <bdi>
                                                        {user?.firstName} {user?.lastName}
                                                    </bdi>
                                                </li>
                                                <li>
                                                    <span>{translate('funds_balance')}</span>
                                                    <bdi>
                                                        {Account.balance} {Account.currency}
                                                    </bdi>
                                                </li>
                                            </ul>
                                            <div className="d-md-flex align-items-end">
                                                <Button
                                                    className="dark-green-button white-color"
                                                    shape="round"
                                                    onClick={() =>
                                                        navigate(`/accounts/show/${Account.loginSid}`)
                                                    }
                                                >
                                                    {translate('view_account')}
                                                </Button>
                                                <Button
                                                    className="dark-green-color"
                                                    style={dir === "ltr" ? {marginLeft: "10px"} : {marginRight: "10px"}}
                                                    shape="round"
                                                    onClick={() =>
                                                        navigate(`/funds/deposit/${Account.loginSid}`)
                                                    }
                                                >
                                                    {translate('top_up')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper> :
                    <Row>
                        {liveAccounts.map((Account, Index) => (
                            <Col key={Index} md={12}>
                                <div className={Style.AccountsOverview}>
                                    <Table
                                        scroll={{x: 560}}
                                        loading={loading}
                                        dataSource={[Account]}
                                        columns={columns}
                                        pagination={false}
                                    />
                                    <div className={Style.AccountsOverviewBox}>
                                        <div className="d-md-flex justify-content-between">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <span>Client Name</span>
                                                    <bdi>
                                                        {user?.firstName} {user?.lastName}
                                                    </bdi>
                                                </li>
                                                <li>
                                                    <span>Balance</span>
                                                    <bdi>
                                                        {Account.balance} {Account.currency}
                                                    </bdi>
                                                </li>
                                            </ul>
                                            <div className="d-md-flex align-items-end">
                                                <Button
                                                    className="dark-green-button white-color"
                                                    shape="round"
                                                    onClick={() =>
                                                        navigate(`/accounts/show/${Account.loginSid}`)
                                                    }
                                                >
                                                    {translate('view_account')}
                                                </Button>
                                                <Button
                                                    className="dark-green-color"
                                                    style={{marginLeft: "10px"}}
                                                    shape="round"
                                                    onClick={() =>
                                                        navigate(`/funds/deposit/${Account.loginSid}`)
                                                    }
                                                >
                                                    {translate('top_up')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                : null}
        </>
    );
};

export default LiveAccounts;
