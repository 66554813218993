import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import {
    createIbReferralsAccountsApi,
    createIbReferralsApi,
    getAccountApi,
    getCurrenciesApi,
    getIbLinksApi
} from "../../../api/api";
import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {Button, Col, Input, message, Modal, Row, Space, Table, DatePicker, Select, Statistic} from "antd";
import {HistoryOutlined, SearchOutlined, UnorderedListOutlined} from "@ant-design/icons";
import Wallet from "../../../components/funds/wallet";
import Copy from "../../../assets/img/icon/copy.svg";
import {useTranslation} from "react-i18next";
import IBTabs from "../../../components/ib/tabs";
import {useNavigate} from "react-router-dom";
import {useWindowSize} from "../../../layout/components/windowSize";
import {ExportToCSV} from "../../../api/common";
import Guides from "../../../components/guides";
const { RangePicker } = DatePicker;

const IBDashboard = () => {

    const navigate = useNavigate();
    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [summary, setSummary] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingWallet, setLoadingWallet] = useState(false);
    const [ibWallet, setIbWallet] = useState();
    const [links, setLinks] = useState([]);
    const [modal, setModal] = useState(false);
    const [IbReferralsAccountsColumns, setIbReferralsAccountsColumns] = useState([]);
    const [IbReferralsAccountsRows, setIbReferralsAccountsRows] = useState([]);
    const [IbReferralsAccountsLoading, setIbReferralsAccountsLoading] = useState(false);
    const {device} = useWindowSize();
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [date, setDate] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState(null);
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        createIbReferrals(null)
        getCurrencies();
        getAccount();
        getIbLinks();
        document.title = `${translate('wingo')} - ${translate('menu_partnership_summary')}`;
    },[]);

    const createIbReferralsAccounts = async (id) => {
        setIbReferralsAccountsLoading(true);
        setModal(true)

        const data = {
            "referralId": id,
            "tableConfig": {
                "filters": [
                    {
                        "field": "",
                        "modificator": "Equals",
                        "value": ""
                    }
                ],
                "segment": {
                    "limit": "500",
                    "offset": 0
                },
                "sorting": {
                    "field": "string",
                    "direction": "DESC"
                },
                "csv": false,
                "withTotals": false
            }
        }
        await createIbReferralsAccountsApi(data, token).then((result)=>{
            let columnsWithSearchProps = result.data.columns
                .filter((x) => x.dataIndex = x.key)
                .map((column) => ({
                    ...column
                }));

            const apiData = result.data.rows;
            const antDesignTableData = apiData.map((item, index) => {
                const dataObj = {};
                item.data.forEach((entry) => {
                    dataObj[entry.key] = entry.value;
                });
                dataObj.key = index.toString();
                return dataObj;
            });

            columnsWithSearchProps.push(
                {
                    key: "tools",
                    dataIndex: "tools",
                    render: (_,_value)=> (<Button onClick={()=>navigate(`/ib/trading/${id}`)} shape="round">{translate('account_trading_history')}</Button>)
                }
            )

            setIbReferralsAccountsColumns(columnsWithSearchProps);
            setIbReferralsAccountsRows(antDesignTableData)

            setIbReferralsAccountsLoading(false);
        }).catch((error)=> {
            console.log(error)
        })
    }

    const getAccount = async () => {
        setLoadingWallet(true)
        const data = {
            category: null,
            scope: null
        }

        await getAccountApi(data, token).then((result)=>{
            const ib = result.data.find((x)=>x.typeId === 2);
            setIbWallet(ib)
            setLoadingWallet(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const getIbLinks = async () => {
        setLoading(true)
        await getIbLinksApi(token).then((result)=>{
            const activeLinks = result.data
                .filter(link => link.isActive)
                .map(link => ({ ...link, key: link.id }));
            setLinks(activeLinks)

            setLoading(false)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const createIbReferrals = async (data) => {
        setLoading(true)
        const newData = {...data, "tableConfig": {withTotals: true}}
        await createIbReferralsApi(newData, token).then((result)=>{

            let columnsWithSearchProps = result.data.columns
                .filter((x) => x.dataIndex = x.key)
                .map((column) => ({
                    ...column,
                    ...getColumnSearchProps(column.key, column.title)
                }));

            columnsWithSearchProps.push(
                {
                    key: "tools",
                    dataIndex: "tools",
                    render: (_,_value)=> <Button onClick={()=>createIbReferralsAccounts(_value.id)} shape="round">{translate('accounts')}</Button>
                }
            )
            setColumns(columnsWithSearchProps)

            const apiData = result.data.rows;
            const apiDataTotal = result.data.totals;

            const antDesignTableData = apiData.map((item, index) => {
                const dataObj = {};
                item.data.forEach((entry) => {
                    dataObj[entry.key] = entry.value;
                });
                dataObj.key = index.toString();
                return dataObj;
            });

            setRows(antDesignTableData);
            setSummary(apiDataTotal);
            setLoading(false)

        }).catch((error)=> {
            console.log(error)
        })
    }

    const columnsLinks = [
        {
            key: 'name',
            dataIndex: 'name',
        },
        {
            key: 'link',
            dataIndex: 'link',
        },
        {
            key: 'tools',
            dataIndex: 'tools',
            render: (_, link) => <Button icon={<img width={14} height={14} src={Copy}/>} className="dark-green-button-border dark-green-color" onClick={()=> copyToClipboard(link.link)} shape="round">{translate('click_to_copy')}</Button>
        }
    ]

    const copyToClipboard = (l) => {
        message.success(translate('copied'))
        navigator.clipboard.writeText(l)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        sorter: (a, b) => {
            const valueA = a[dataIndex];
            const valueB = b[dataIndex];

            if (!isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB))) {
                return parseFloat(valueA) - parseFloat(valueB);
            } else {
                return valueA && valueA.localeCompare(valueB);
            }
        },
        sortDirections: ['ascend', 'descend'],
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                <span>{text}</span>
            ),
    });

    const handleChangeDate = () => {
        let data;

        if(date.length){
            data = {
                from: date[0],
                to: date[1],
                currency: currency
            }

        } else {
            data = null;
        }

        setModal(false)
        createIbReferrals(data)
    }

    const getCurrencies = async () => {
        await getCurrenciesApi(token).then((result)=> {
            const convertedArray = result.data.map(currencyCode => ({
                label: currencyCode,
                value: currencyCode,
            }));
            setCurrencies(convertedArray)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const Summary = () => {
        return (
            <Row gutter={16} className="w-100">
                <Col md={4} xs={12}>
                    <Statistic title={translate('funds_balance')} value={summary.balance}/>
                </Col>
                <Col md={4} xs={12}>
                    <Statistic title={translate('ib_commissions')} value={summary.commission} />
                </Col>
                <Col md={4} xs={12}>
                    <Statistic title={translate('menu_funds_deposit')} value={summary.deposits} />
                </Col>
                <Col md={4} xs={12}>
                    <Statistic title={translate('withdrawals')} value={summary.withdrawals} />
                </Col>
                <Col md={4} xs={12}>
                    <Statistic title={translate('lots')} value={summary.lots} />
                </Col>
                <Col md={4} xs={12}>
                    <Statistic title={translate('total_number_of_trades')} value={summary.totalNumberOfTrades} />
                </Col>
            </Row>
        )
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_partnership')}</h1>
                <Guides type="ibPanel" locale={lang}/>

                <IBTabs/>
                <h2>{translate('menu_partnership_summary')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`} style={{marginBottom: "30px"}}>
                    <div className="d-md-flex justify-content-end">
                        <div className={`d-md-flex align-items-center ${Style.IBSummaryWrapper}`}>
                            <Button onClick={()=>navigate(('/ib/transactions'))} icon={<UnorderedListOutlined />} style={device === "mobile" ? {marginBottom: "10px"} : (dir === "ltr" ? {marginLeft: "10px"} : {marginRight: "10px"})} shape="round">
                                <strong>{translate('ib_transactions')}</strong>
                            </Button>
                            <Button onClick={()=> navigate('/ib/detailed-commission-breakdown')} icon={<HistoryOutlined />} style={dir === "ltr" ? {marginLeft: "10px"} : dir === "rtl" ? {marginRight: "10px"} : null} shape="round">
                                <strong>{translate('ib_deleted_commission_breakdown')}</strong>
                            </Button>
                        </div>
                    </div>

                    <Row gutter={20} style={{marginTop: "20px"}}>
                        <Col md={12} className="ant-col-full">
                            <ul className="list-unstyled m-0 p-0">
                                <Wallet wallet={ibWallet} loading={loadingWallet} type={2}/>
                            </ul>
                        </Col>
                        <Col md={12} className="d-md-flex justify-content-end ant-col-full">
                            <div className={`${Style.OverviewTable} ${Style.PanelBoxWrapper}`} style={device === "mobile" ? {marginTop: "15px"} : {}}>
                                <h3 className="m-0 p-0">{translate('ib_links')}</h3>
                                <Table
                                    loading={loading}
                                    className={Style.LinksTable}
                                    dataSource={links}
                                    columns={columnsLinks}
                                    pagination={false}
                                    scroll={{
                                        y: 170,
                                        x: 560
                                    }}
                                />
                            </div>
                        </Col>

                    </Row>

                </div>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`} style={{marginBottom: "30px"}}>
                    <Row gutter={30} className={Style.LinksForm}>
                        <Col md={12}>
                            <div className="d-flex">
                                <label>{translate('date')}</label>
                                <RangePicker
                                    style={{borderRadius: "25px", marginBottom: "15px"}}
                                    format="YYYY-MM-DD"
                                    className="w-100"
                                    onChange={(_, date)=>(_ ? setDate(date) : setDate([]))}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="d-flex">
                                <label>{translate('funds_currency')}</label>
                                <Select placeholder={translate('funds_currency')} options={currencies} className="w-100" onChange={(choice)=> setCurrency(choice)}/>
                            </div>
                        </Col>
                        <Col span={2}>
                            <Button shape="round" className="dark-green-button white-color" onClick={handleChangeDate}>{translate('confirm')}</Button>
                        </Col>
                    </Row>
                </div>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h3>{translate('ib_direct_referrals')}</h3>

                    </div>
                    <Summary/>
                    <Table
                        style={{marginTop: "20px"}}
                        scroll={{x: 560}}
                        loading={loading}
                        dataSource={rows}
                        columns={columns}
                        footer={()=>
                            <ExportToCSV data={rows} dir={dir} translate={translate} name="wingo_ib_referrals"/>
                        }
                    />
                </div>
            </div>

            <Modal
                centered
                open={modal}
                width={device !== "mobile" ? "60%" : "100%"}
                closable={false}
                onCancel={()=> setModal(false)}
                title={[
                    <div key={1} className={`${Style.AccountModalHeader} ${Style.AccountModalHeaderSmall}`}>
                        <span>{translate('accounts')}</span>
                    </div>
                ]}
                footer={[
                    <div key={1} className="d-flex justify-content-end" style={{ margin: "30px" }}>
                        <Button shape="round" onClick={() => setModal(false)}>{translate('close')}</Button>
                    </div>
                ]}
            >
                <Table
                    scroll={{x: 560}}
                    loading={IbReferralsAccountsLoading}
                    dataSource={IbReferralsAccountsRows}
                    columns={IbReferralsAccountsColumns}
                />
            </Modal>
        </PanelLayout>
    )
}

export default IBDashboard;