import {Tabs} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const IBReportsTabs = () => {

    const { t } = useTranslation();
    const translate = t;
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [activeKey, setActiveKey] = useState(null);

    useEffect(()=>{
        urlToKey(pathname)
    },[pathname]);

    const urlToKey = (path) => {
        if (pathname.includes(path)) {
            const find = items.find((x) => x.url.indexOf(path) !== -1);

            if(find){
                setActiveKey(find.key)
            }
        }
    }

    const onChange = (key) => {
        const find = items.find((x) => x.key === key);
        navigate(find.url)
        setActiveKey(key)
    }

    const items = [
        {
            key: 1,
            label: translate('menu_partnership_accounts_commission'),
            url: '/ib/reports/accounts-commission',
        },
        {
            key: 2,
            label: translate('menu_partnership_clients_commission'),
            url: '/ib/reports/clients-commission',
        },
        {
            key: 3,
            label: translate('menu_partnership_cpa'),
            url: '/ib/reports/cpa-payments',
        }
    ];

    return (
        <>
            <Tabs activeKey={activeKey} items={items} onChange={onChange} />
        </>
    )
}

export default IBReportsTabs;