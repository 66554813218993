import {useNavigate, useParams} from "react-router-dom";
import Style from "../../../../assets/css/panel.module.css";
import PanelLayout from "../../../../layout/panel";
import {useTranslation} from "react-i18next";
import Verified from "../../../../assets/img/icon/check-verified.svg";
import {Button} from "antd";

const WithdrawCallback = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const translate = t;
    const Route = useParams();
    const {type} = Route;

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_funds')}</h1>
                <div className={Style.PanelBox}>
                    <div className={Style.PanelWrapperPadding}>
                        <h3 style={{marginTop: "0px"}}>{translate('menu_funds_withdraw')}</h3>
                        <div className="text-center" style={{marginBottom: "15px"}}>
                            <img width={87} height={87} src={Verified} />
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{marginTop: "30px"}}>
                            <Button onClick={()=>navigate("/funds/withdraw")} style={{borderRadius: "25px"}} size="large" className="dark-green-button white-color">{translate('back')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </PanelLayout>
    )
}

export default WithdrawCallback;