import axios from "axios";
import {Api} from "./config";

const Axios = axios.create({
    baseURL: Api,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    },
    params: {'version': '1.0.0'}
});

export const get = (url, config) => {
    return Axios.get(url, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/logout?error=401";
        } else {
            return Promise.reject(error)
        }
    });
}


export const post = (url, data, config) => {
    return Axios.post(url, data, config).then((result)=>{
        return result;
    }).catch((error) => {
        console.log(error)
        if(error?.response?.status === 401){
            window.location.href = "/logout?error=401";
        } else {
            return Promise.reject(error)
        }
    });
}

export const put = (url, data, config) => {
    return Axios.put(url, data, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/logout?error=401";
        } else {
            return Promise.reject(error)
        }
    });
}

export const remove = (url, config) => {
    return Axios.delete(url, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/logout?error=401";
        } else {
            return Promise.reject(error)
        }
    });
}

export const patch = (url, data, config) => {
    return Axios.patch(url, data, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/logout?error=401";
        } else {
            return Promise.reject(error)
        }
    });
}