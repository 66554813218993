import Style from "../../../assets/css/panel.module.css";
import {Menu, notification, Tag} from 'antd';
import {useEffect, useState} from "react";
import Logo from "../../../assets/img/logo.svg";
import MyHome from "../../../assets/img/icon/home-icon.svg";
import MyFunds from "../../../assets/img/icon/dollar-icon.svg";
import MyAccounts from "../../../assets/img/icon/bars-icon.svg";
import MyTools from "../../../assets/img/icon/tools-icon.svg";
import Platform from "../../../assets/img/icon/bar-chart-icon.svg";
import WebTerminal from "../../../assets/img/icon/terminal-icon.svg";
import SocialTrading from "../../../assets/img/icon/line-chart-up-icon.svg";
import Partnership from "../../../assets/img/icon/users-icon.svg";
import RequestVPS from "../../../assets/img/icon/server-icon.svg";
import Profile from "../../../assets/img/icon/user-icon.svg";
import HelpDesk from "../../../assets/img/icon/life-buoy-icon.svg";
import Logout from "../../../assets/img/icon/log-out-icon.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getUnreadMessageCountApi, getUnreadTicketsCountApi, logoutApi} from "../../../api/api";
import {setNav, setToken, setUserData, setUnread} from "../../../redux/actions/user";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import { CloseOutlined } from '@ant-design/icons';

const Sidebar = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const user = useSelector((state) => state.auth?.user || null);
    const nav = useSelector((state) => state.auth?.nav || null);
    const unreads = useSelector((state) => state.auth?.unread || {});
    const { t } = useTranslation();
    const translate = t;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [openKeys, setOpenKeys] = useState([]);
    const [unreadCount, setUnreadCount] = useState({});

    useEffect(()=>{

        if (pathname.includes('/funds')) {
            const find = items1.find((x) => x.url === '/funds');
            if(find){
                setOpenKeys([String(find.key)])
            }
        } else if(pathname.includes('/dashboard')){
            const find = items1.find((x) => x.url === '/dashboard');
            if(find){
                setOpenKeys([String(find.key)])
            }
        } else if(pathname.includes('/ib')){
            const find= items1.find((x) => x.url === '/ib');
            if(find){
                setOpenKeys([String(find.key)])
            }
        } else if(pathname.includes('/accounts')){
            const find = items1.find((x) => x.url === '/accounts');
            if(find){
                setOpenKeys([String(find.key)])
            }
        } else if(pathname.includes('/tools')){
            const find = items1.find((x) => x.url === '/tools');
            if(find){
                setOpenKeys([String(find.key)])
            }
        } else if(pathname.includes('/profile')){
            const find= items2.find((x) => x.url === '/profile');
            if(find){
                setOpenKeys([String(find.key)])
            }
        } else if(pathname.includes('/helpdesk')){
            const find= items2.find((x) => x.url === '/helpdesk');
            if(find){
                setOpenKeys([String(find.key)])
            }
        }

    },[pathname]);

    useEffect(()=>{
        handleCloseNav()

        if(!Object.entries(unreads).length){
            getUnreadTicketsCount();
            getUnreadMessageCount();
        } else {
            setUnreadCount(unreads)
        }
    },[])

    const getUnreadTicketsCount = async () => {
        await getUnreadTicketsCountApi(token).then((result)=>{
            dispatch(setUnread({...unreads, help: result.data}))
            setUnreadCount({...unreadCount, help: result.data})
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getUnreadMessageCount = async () => {
        await getUnreadMessageCountApi(token).then((result)=>{
            dispatch(setUnread({...unreads, messages: result.data.data.count}))
            setUnreadCount({...unreadCount, messages: result.data.data.count})
        }).catch((error)=>{
            console.log(error)
        })
    }

    const onOpenChange = (keys) => {
        setOpenKeys(keys)
    }

    const items1 = [
        {
            key: 1,
            icon: <img width={27} height={27} src={MyHome} />,
            label: <Link to={"/dashboard"}>{translate('menu_my_home')}</Link>,
            url: '/dashboard'
        },
        {
            key: 2,
            label: translate('menu_my_funds'),
            icon: <img width={27} height={27} src={MyFunds} />,
            url: '/funds',
            children: [
                {
                    key: 13,
                    label: <Link to={"/funds/wallets"}>{translate('menu_funds_wallets')}</Link>
                },
                {
                    key: 14,
                    label: <Link to={"/funds/deposit"}>{translate('menu_funds_deposit')}</Link>
                },
                {
                    key: 15,
                    label: <Link to={"/funds/withdraw"}>{translate('menu_funds_withdraw')}</Link>
                },
                {
                    key: 16,
                    label: <Link to={"/funds/transfer"}>{translate('menu_funds_transfer_funds')}</Link>,
                },
                {
                    key: 17,
                    label: <Link to={"/funds/details"}>{translate('menu_funds_payment_details')}</Link>
                },
                {
                    key: 18,
                    label: <Link to={"/funds/history"}>{translate('menu_history')}</Link>
                }
            ]
        },
        {
            key: 3,
            label: translate('menu_my_accounts'),
            icon: <img width={20} height={20} src={MyAccounts} />,
            url: '/accounts',
            children: [
                {
                    key: 19,
                    label: <Link to={"/accounts/overview"}>{translate('menu_accounts_overview')}</Link>
                },
                {
                    key: 23,
                    label: <Link to={"/accounts/demo"}>{translate('menu_demo_account')}</Link>
                },
                {
                    key: 24,
                    label: <Link to={"/accounts/live"}>{translate('menu_live_account')}</Link>
                },
                {
                    key: 40,
                    label: <Link to={"/accounts/new"}>{translate('dashboard_new_account')}</Link>
                }
            ]
        },
        {
            key: 4,
            label: translate('menu_my_tools'),
            icon: <img width={27} height={27} src={MyTools} />,
            url: '/tools',
            children: [
                {
                    key: 20,
                    label: <Link to={"/tools/trading-calculator"}>{translate('menu_trading_calculator')}</Link>
                },
                {
                    key: 25,
                    label: <Link to={"/tools/economic-calendar"}>{translate('menu_economic_calendar')}</Link>
                }
            ]
        },
        {
            key: 5,
            icon: <img width={27} height={27} src={Platform} />,
            label: <Link to={"/platform"}>{translate('menu_platform')}</Link>
        },
        {
            key: 6,
            icon: <img width={22} height={20} src={WebTerminal} />,
            label: <Link to={"/terminal"}>{translate('menu_web_terminal')}</Link>
        },
        {
            key: 7,
            icon: <img width={27} height={27} src={SocialTrading} />,
            label: translate('menu_social_trading'),
            children: [
                {
                    key: 38,
                    label: <Link to={"/social/login"}>{translate('social_login')}</Link>
                },
                {
                    key: 39,
                    label: <Link to={"/social/provider"}>{translate('social_provider')}</Link>
                },
                {
                    key: 41,
                    label: <Link to={"/social/top10"}>{translate('top10')}</Link>
                }
            ]
        },
        {
            key: 8,
            icon: <img width={27} height={27} src={Partnership} />,
            label: translate('menu_partnership'),
            url: '/ib',
            children: user?.isIb ? [
                {
                    key: 30,
                    label: <Link to={"/ib/dashboard"}>{translate('menu_partnership_summary')}</Link>
                },
                {
                    key: 31,
                    label: <Link to={"/ib/performance"}>{translate('menu_partnership_performance')}</Link>
                },
                {
                    key: 32,
                    label: <Link to={"/ib/links"}>{translate('menu_partnership_links')}</Link>
                },
                {
                    key: 33,
                    label: <Link to={"/ib/banners"}>{translate('menu_partnership_banners')}</Link>
                },
                {
                    key: 34,
                    label: translate('reports'),
                    children: [
                        { key: 35, label: <Link to={"/ib/reports/accounts-commission"}>{translate('menu_partnership_accounts_commission')}</Link> },
                        { key: 36, label: <Link to={"/ib/reports/clients-commission"}>{translate('menu_partnership_clients_commission')}</Link> },
                        { key: 37, label: <Link to={"/ib/reports/cpa-payments"}>{translate('menu_partnership_cpa')}</Link> }
                    ]
                }
            ] : [
                {
                    key: 21,
                    label: <Link to={"/ib"}>{translate('request_ib_page')}</Link>
                }
            ]
        },
        {
            key: 9,
            icon: <img width={27} height={27} src={RequestVPS} />,
            label: translate('menu_request_vps'),
            disabled: true
        }
    ];

    const items2 = [
        {
            key: 10,
            icon: <img width={27} height={27} src={Profile} />,
            label: translate('menu_profile'),
            url: '/profile',
            children: [
                {
                    key: 22,
                    label: <Link to={"/profile"}>{translate('menu_profile')}</Link>
                },
                {
                    key: 26,
                    label: <Link to={"/profile/verification"}>{translate('menu_verification')}</Link>
                },
                {
                    key: 27,
                    label: <Link to={"/profile/agreements"}>{translate('menu_my_agreements')}</Link>
                },
                {
                    key: 28,
                    label: <>
                        {unreadCount?.messages ? <Tag bordered={false} style={{marginLeft: "10px", fontSize: "11px"}} color="success">{unreadCount?.messages}</Tag> : null}
                        <Link to={"/profile/messages"}>{translate('menu_messages')}</Link>
                    </>
                },
                {
                    key: 29,
                    label: <Link to={"/profile/two-factor"}>{translate('menu_two_factor_authentication')}</Link>
                }
            ]
        },
        {
            key: 11,
            icon: <img width={27} height={27} src={HelpDesk} />,
            label: <>
                <Link to={"/helpdesk/open-tickets"}>{translate('menu_help_dek')}</Link>
                {unreadCount?.help ? <Tag bordered={false} style={{marginLeft: "10px", fontSize: "11px"}} color="success">{unreadCount?.help}</Tag> : null}
                </>,
            url: '/helpdesk'
        },
        {
            key: 12,
            icon: <img width={27} height={27} src={Logout} />,
            label: translate('menu_logout')
        }
    ];


    const onChange = (menu) => {
        if(menu.key == 12){
            handleLogOut()
        }
    }

    const handleLogOut = async () => {
        await logoutApi(false, token).then(()=>{
            dispatch(setToken(null));
            dispatch(setUserData(null));

            notification.success({
                message: translate('logout_message')
            })
        })
        navigate("/login")
    }

    const handleCloseNav = () => {
        dispatch(setNav(false))
    }

    return (
        <div className={Style.SideBarWrapper}>
            <div onClick={()=> handleCloseNav()} className={`${Style.MobileBackDrop} ${nav ? Style.MobileBackDropActive : ``}`}></div>
            <aside className={`${Style.SideBar} ${nav ? Style.SideBarActive : ``} hidden-scrollbar`}>
                <div className={Style.SideBarLogo}>
                    <img src={Logo}/>
                    <span className={Style.CloseNav} onClick={()=>handleCloseNav()}>
                        <CloseOutlined />
                    </span>
                </div>
                <div className="w-100 h-100 d-flex flex-wrap" style={{height: "calc(100% - 105px)"}}>
                    <Menu
                        className={Style.SideBarMenu}
                        mode="inline"
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        items={items1}
                        selectedKeys={openKeys}
                    />
                    <Menu
                        className={Style.SideBarMenu}
                        style={{marginTop: "auto"}}
                        mode="inline"
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        items={items2}
                        selectedKeys={openKeys}
                        onSelect={onChange}
                    />
                </div>
            </aside>
        </div>
    )
}

export default Sidebar;