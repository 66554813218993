import {Button, Input, notification, Checkbox} from "antd";
import {checkTwoFactorCodeApi, getAccessTokenApi} from "../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {setSetting, setToken, setUserData} from "../../redux/actions/user";
import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import AuthLayout from "../../layout/auth";
import Splash1 from "../../assets/img/auth/slide-1.png";
import Splash2 from "../../assets/img/auth/slide-2.png";
import Style from "../../assets/css/panel.module.css";
import Logo from "../../assets/img/logo-alt.svg";
import Language from "../../layout/components/common/language";
import { useTranslation } from 'react-i18next';
import Password from "antd/es/input/Password";
import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination, Autoplay } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/pagination';
import { Helmet } from 'react-helmet';

const Login = () => {

    const { t } = useTranslation();
    const translate = t;
    const params = useLocation();
    const {search} = params;
    const error = new URLSearchParams(search).get("error");
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const token = useSelector((state) => state.auth?.token || null);
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        rememberMe: true
    });
    const [twoFaData, setTwoFaData] = useState({
        rememberMe: true,
        _trusted: "off"
    });
    const [twoFa, setTwoFa] = useState(false);
    const dispatch = useDispatch();
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        document.title = `${translate('wingo')} - ${translate('login')}`;
        if(token){
            navigate('/dashboard')
        }
    }, []);

    useEffect(()=>{
        if(error){
            if(error === "401"){
                notification.error({description: "Username or password is wrong"})
            }
        }
    },[params]);

    const login = async () => {
        setButtonLoading(true)
        await getAccessTokenApi(loginData).then((result)=>{

            setButtonLoading(false)

            if(result.status === 200){

                dispatch(setUserData(result?.data?.client))
                dispatch(setToken(result?.data?.accessToken))
                dispatch(setSetting({lang: result?.data?.client?.language,
                    dir: result?.data?.client?.language === "fa" ? "rtl" : "ltr"}))

                notification.success({
                    message: translate('error_logged')
                })

                navigate('/dashboard');

            } else if(result.status === 202){
                setTwoFa(true)

                notification.success({
                    message: translate('error_2fa')
                })
            }

        }).catch((error)=>{
            const message = error?.response?.data?.message;
            if(message){
                notification.error({
                    message: message
                })
            }
            setButtonLoading(false)
        })
    }

    const checkTwoFactorCode = async () => {
        setButtonLoading(true)
        await checkTwoFactorCodeApi(twoFaData).then((result)=>{
            if(result.data.accessToken){
                dispatch(setUserData(result?.data?.client))
                dispatch(setToken(result?.data?.accessToken))
                dispatch(setSetting({lang: result?.data?.client?.language,
                    dir: result?.data?.client?.language === "fa" ? "rtl" : "ltr"}))

                notification.success({
                    message: translate('error_logged')
                })
                setButtonLoading(false)
                navigate('/dashboard');
            } else {
                notification.error({
                    message: result?.data?.message
                })
                setButtonLoading(false)
            }
        }).catch((error)=>{
            setTwoFa(false);
            setButtonLoading(false)
            console.log(error)
        })
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <AuthLayout>
                <div className="mh-md-100 d-md-flex">
                    <div className={Style.AuthSider}>
                        <div className={Style.AuthSplash}>
                            <div className="d-flex flex-wrap justify-content-center align-items-center mh-md-100vh">
                                <Swiper
                                    dir={dir}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination, Autoplay]}
                                    className={Style.AuthSlider}
                                    autoplay={{
                                        delay: 5000
                                    }}
                                >
                                    <SwiperSlide>
                                        <div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <img src={Splash1} />
                                            </div>
                                            <div className={`${Style.AuthSlogan} text-center`}>
                                                <bdi>{translate('slider1')}</bdi>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <img src={Splash2} />
                                            </div>
                                            <div className={`${Style.AuthSlogan} text-center`}>
                                                <bdi>{translate('slider2')}</bdi>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className={Style.AuthSider}>
                        <div className={`${Style.AuthForm} ${Style.AuthLoginSplash}`}>
                            <div className={Style.AuthLogin}>
                                <div>
                                    <div className={`${Style.LoginHeader} d-flex align-items-center justify-content-between`}>
                                        <img width={187} height={45} src={Logo}/>
                                        <Language style={{marginRight: "0px"}}/>
                                    </div>
                                    {twoFa ? <h6>{translate('authentication_code')}</h6>
                                        :
                                        <h6>{translate('login')}</h6>}
                                </div>
                                {twoFa ?
                                    <div>
                                        <Input
                                            key={3}
                                            name="2fa"
                                            placeholder={translate('pin_2fa')}
                                            style={{borderRadius: '25px'}}
                                            value={twoFaData?._auth_code}
                                            onChange={(e)=> setTwoFaData({...twoFaData, _auth_code: e.target.value})}
                                        />
                                        <div style={{marginTop: "15px"}} className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center justify-content-start">
                                                <Checkbox
                                                    id="trust"
                                                    name="trusted"
                                                    onChange={(e)=>setTwoFaData({...twoFaData, _trusted: e.target.checked ? "on" : "off"})}
                                                />
                                                <label htmlFor="trust" style={dir === "ltr" ? {marginLeft: "10px"} : {marginRight: "10px"}} className="text-nowrap">{translate('trust_device')}</label>
                                            </div>

                                        </div>
                                        <Button
                                            style={{marginTop: "35px"}}
                                            shape="round"
                                            className="dark-green-button white-color"
                                            loading={buttonLoading}
                                            onClick={()=>checkTwoFactorCode()}
                                            disabled={!twoFaData?._auth_code}
                                        >
                                            {translate('confirm')}
                                        </Button>
                                        <Button
                                            style={dir === "ltr" ? {marginLeft: "10px"} : {marginRight: "10px"}}
                                            onClick={()=>setTwoFa(false)}
                                            shape="round">{translate('back')}
                                        </Button>
                                    </div> :
                                    <div>
                                        <Input
                                            key={1}
                                            style={{borderRadius: '25px', marginBottom: '22px'}}
                                            autofill="false"
                                            name="email"
                                            value={loginData?.email}
                                            placeholder={translate('profile_email')}
                                            onChange={(e)=>setLoginData({...loginData, email: e.target.value})}
                                        />
                                        <Password
                                            key={2}
                                            style={{borderRadius: '25px'}}
                                            autoComplete="new-password"
                                            placeholder={translate('profile_password')}
                                            name="password"
                                            type="password"
                                            value={loginData?.password}
                                            onChange={(e)=>setLoginData({...loginData, password: e.target.value})}
                                        />

                                        <div className="d-flex justify-content-between" style={{marginTop: "15px"}}>
                                            <div className="d-flex align-items-center">
                                                {/*<Checkbox
                                                name="remember"
                                                id="remember"
                                                onChange={(e)=>setLoginData({...loginData, rememberMe: e.target.checked})}
                                            />
                                            <label htmlFor="remember" style={dir === "ltr" ? {marginLeft: "10px"} : {marginRight: "10px"}} className="text-nowrap">{translate('remember_me')}</label>*/}
                                            </div>
                                            <div>
                                                <Link to={"/forget-password"}>{translate('forget_password')}</Link>
                                            </div>
                                        </div>
                                        <Button
                                            style={{marginTop: "35px"}}
                                            shape="round"
                                            size="large"
                                            className="dark-green-button white-color"
                                            loading={buttonLoading}
                                            onClick={()=>login()}
                                            disabled={!loginData?.email || !loginData?.password}
                                        >
                                            {translate('login')}!
                                        </Button>
                                    </div>
                                }
                            </div>
                            <div className={Style.AuthNotRegister}>
                                <h6>{translate('not_register')}</h6>
                                <span>{translate('register_note')}</span>
                                <Button size="large" onClick={()=> navigate("/register")} className="dark-green-button white-color" shape="round" style={{marginTop: "25px", display: "flex"}}>{translate('register')}</Button>
                                <p>{translate('register_warning')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </AuthLayout>
        </>
    )
}

export default Login;