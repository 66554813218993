import {Tabs} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const IBTabs = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [activeKey, setActiveKey] = useState(null);
    const { t } = useTranslation();
    const translate = t;

    useEffect(()=>{
        urlToKey(pathname)
    },[pathname]);

    const urlToKey = (path) => {
        if (pathname.includes(path)) {
            const find = items.find((x) => path.startsWith(x.url));

            if(find){
                setActiveKey(find.key)
            }
        }
    }

    const onChange = (key) => {
        const find = items.find((x) => x.key === key);
        if(key === 5){
            navigate('/ib/reports/accounts-commission')
        } else {
            navigate(find.url)
        }

        setActiveKey(key)
    }

    const items = [
        {
            key: 1,
            label: translate('menu_partnership_summary'),
            url: '/ib/dashboard',
        },
        {
            key: 2,
            label: translate('menu_partnership_performance'),
            url: '/ib/performance',
        },
        {
            key: 3,
            label: translate('menu_partnership_links'),
            url: '/ib/links',
        },
        {
            key: 4,
            label: translate('menu_partnership_banners'),
            url: '/ib/banners',
        },
        {
            key: 5,
            label: translate('reports'),
            url: '/ib/reports',
        }
    ];

    return (
        <>
            <Tabs activeKey={activeKey} items={items} onChange={onChange} />
        </>
    )
}

export default IBTabs;