import {logoutApi} from "../../api/api";
import {setContactManager, setToken, setUserData} from "../../redux/actions/user";
import {notification, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import { LoadingOutlined } from '@ant-design/icons';

export const Logout = () => {

    const navigate = useNavigate();
    const params = useLocation();
    const {search} = params;
    const error = new URLSearchParams(search).get("error");
    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const dispatch = useDispatch();

    useEffect(()=>{
        if(token){
            logout();
        } else {
            if(error){
                navigate(`/login?error=${error}`)
            } else {
                navigate("/login")
            }
        }
    },[])

    const logout = async () => {
        await logoutApi(false, token).then(()=>{
            dispatch(setToken(null));
            dispatch(setUserData(null));
            dispatch(setContactManager(null))

            notification.success({
                message: translate('logout_message')
            })
            navigate('/login');
        })
    }

    return (
        <div className="d-flex align-items-center justify-content-center" style={{paddingTop: "100px"}}>
            <Spin
                indicator={
                    <LoadingOutlined
                        style={{
                            fontSize: 24,
                        }}
                        spin
                    />
                }
            />
        </div>
    )
}

export default Logout;