import Style from "../../../assets/css/panel.module.css";
import ProfileTabs from "../../../components/profile/tabs";
import PanelLayout from "../../../layout/panel";
import {Col, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import {getAcceptedCompanyDocumentsApi} from "../../../api/api";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Base_Url} from "../../../api/config";
import Pdf from "../../../assets/img/agreements/pdf.svg";
import Arrow from "../../../assets/img/agreements/arrow-square-up-right.svg";
import {useTranslation} from "react-i18next";

const Agreements = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('menu_messages')}`;
    },[]);

    useEffect(()=>{
        getAcceptedCompanyDocuments()
    },[])

    const getAcceptedCompanyDocuments = async () => {
        setLoading(true)
        await getAcceptedCompanyDocumentsApi(token).then((result)=>{
            setDocuments(result.data)
            setLoading(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_profile')}</h1>
                <ProfileTabs/>
                <h2>{translate('menu_my_agreements')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h3>{translate('accepted_documents')}</h3>

                    <div>
                        {loading ? <Spin/> : null}
                    </div>

                    <Row gutter={68}>
                        {documents?.map((Doc, Index)=>{
                            return (
                                <Col key={Index} md={8} style={{marginBottom: "28px"}} className="ant-col-full">
                                    <div className={Style.DocumentList}>
                                        <img width={72} height={72} src={Pdf}/>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <span>{Doc.name}</span>
                                            <div className={Style.DocumentVersion}>
                                                <bdi>V{Doc.version}</bdi>
                                                <Link to={Base_Url + Doc.path}><img src={Arrow}/></Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </div>
        </PanelLayout>
    )
}

export default Agreements;