import PanelLayout from "../../../../layout/panel";
import Style from "../../../../assets/css/panel.module.css";
import {getIbTransactionsApi} from "../../../../api/api";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Input, Space, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ExportToCSV} from "../../../../api/common";

const  IBTransactions = () => {

    const navigate = useNavigate();
    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    useEffect(()=>{
        getIbTransactions();
        document.title = `${translate('wingo')} - ${translate('ib_transactions')}`;
    },[]);

    const getIbTransactions = async () => {
        setLoading(true)
        const data = {
            "tableConfig": {
                "filters": [
                    {
                        "field": "",
                        "modificator": "Equals",
                        "value": ""
                    }
                ],
                "segment": {
                    "limit": "500",
                    "offset": 0
                },
                "sorting": {
                    "field": "string",
                    "direction": "DESC"
                },
                "csv": false,
                "withTotals": false
            }
        }

        await getIbTransactionsApi(data, token).then((result)=>{
            const columnsWithSearchProps = result.data.columns
                .filter((x) => x.dataIndex = x.key)
                .map((column) => ({
                    ...column,
                    ...getColumnSearchProps(column.key, column.title),
                }));
            setColumns(columnsWithSearchProps);

            const apiData = result.data.rows;
            const antDesignTableData = apiData.map((item, index) => {
                const dataObj = {};
                let code = null;

                item.data.forEach((entry) => {
                    if(entry.key.includes("preview")){
                        code = entry.value
                    }
                    dataObj[entry.key] = entry.value
                });
                dataObj.key = index.toString();
                return dataObj;
            });

            setRows(antDesignTableData);
            setLoading(false)

        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        sorter: (a, b) => {
            const valueA = a[dataIndex];
            const valueB = b[dataIndex];

            if (!isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB))) {
                return parseFloat(valueA) - parseFloat(valueB);
            } else {
                return valueA && valueA.localeCompare(valueB);
            }
        },
        sortDirections: ['ascend', 'descend'],
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                <span>{text}</span>
            ),
    });

    return (
        <PanelLayout>

            <div className={Style.Main}>
                <h1>{translate('menu_partnership')}</h1>
                <div style={{marginTop: "50px"}} className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h2>{translate('ib_transactions')}</h2>
                        <Button size="large" onClick={() => navigate(-1)} shape="round" style={{ marginRight: "25px" }}>{translate('back')}</Button>
                    </div>
                    <Table
                        scroll={{x: 560}}
                        loading={loading}
                        columns={columns}
                        dataSource={rows}
                        footer={()=> <ExportToCSV data={rows} dir={dir} translate={translate} name="wingo_cpa_payments"/>}
                    />
                </div>
            </div>
        </PanelLayout>
    )
}

export default IBTransactions;