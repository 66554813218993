import {CSVLink} from "react-csv";
import Document from "../assets/img/icon/document-file-sharing.svg";

export const ExportToCSV = ({data, dir, translate, name}) => {
    return (
        <div className="d-flex align-items-center justify-content-end" style={{fontSize: "14px",margin: "10px 0"}}>
            <CSVLink filename={`${name}.csv`} data={data} className="d-flex align-items-center dark-green-color">
                <img alt="" src={Document}/>
                <bdi style={dir === "ltr" ? {marginLeft: "10px"} : dir === "rtl" ? {marginRight: "10px"} : null}>{translate('export_csv')}</bdi>
            </CSVLink>
        </div>
    )
}