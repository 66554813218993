import {Col, Row} from "antd";
import Style from "../../assets/css/panel.module.css";
import icon1 from "../../assets/img/quick/icon-1.svg";
import icon2 from "../../assets/img/quick/icon-2.svg";
import icon3 from "../../assets/img/quick/icon-3.svg";
import icon4 from "../../assets/img/quick/icon-4.svg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Quick = () => {

    const { t } = useTranslation();
    const translate = t;

    return (
        <Row className={Style.DashboardSection}>
            <h6>{translate('dashboard_quick_links')}</h6>

            <Col span={24}>
                <Row gutter={[{ xs: 10, sm: 10, md: 10, lg: 25 }, { xs: 10, sm: 10, md: 10, lg: 25 }]}>
                    <Col xl={6} lg={12} md={6} xs={12}>
                        <Link to={"/funds/deposit"}>
                            <div className={`${Style.PanelBox} d-flex ${Style.QuickLinks}`}>
                                <div className={Style.QuickLinksIconBox}>
                                    <img src={icon1} alt=""/>
                                </div>
                                <h5>{translate('dashboard_deposit_funds')}</h5>
                            </div>
                        </Link>
                    </Col>
                    <Col xl={6} lg={12} md={6} xs={12}>
                        <Link to={"/funds/transfer"}>
                            <div className={`${Style.PanelBox} d-flex ${Style.QuickLinks}`}>
                                <div className={Style.QuickLinksIconBox}>
                                    <img src={icon2} alt=""/>
                                </div>
                                <h5>{translate('dashboard_internal_transfer')}</h5>
                            </div>
                        </Link>
                    </Col>
                    <Col xl={6} lg={12} md={6} xs={12}>
                        <Link to={"/accounts/new"}>
                            <div className={`${Style.PanelBox} d-flex ${Style.QuickLinks}`}>
                                <div className={Style.QuickLinksIconBox}>
                                    <img src={icon3} alt=""/>
                                </div>
                                <h5>{translate('dashboard_new_account')}</h5>
                            </div>
                        </Link>
                    </Col>
                    <Col xl={6} lg={12} md={6} xs={12}>
                        <Link to={"/funds/withdraw"}>
                            <div className={`${Style.PanelBox} d-flex ${Style.QuickLinks}`}>
                                <div className={Style.QuickLinksIconBox}>
                                    <img src={icon4} alt=""/>
                                </div>
                                <h5>{translate('dashboard_withdraw_funds')}</h5>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Quick;