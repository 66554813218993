import PanelLayout from "../../../layout/panel";
import {useNavigate, useParams} from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {Button, Col, Row} from "antd";
import Style from "../../../assets/css/panel.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getChartByNameApi} from "../../../api/api";
import {useSelector} from "react-redux";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const AccountStats = () => {

    const navigate = useNavigate();
    const Route = useParams();
    const token = useSelector((state) => state.auth?.token || null);
    const {account_id} = Route;
    const { t } = useTranslation();
    const translate = t;
    const [ibLotsChart, setIbLotsChart] = useState([]);
    const [ibCommissions, setIbCommissions] = useState([]);

    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('account_stats')}`;

        getChartByName("ib_lots");
        getChartByName("ib_commissions");
        getChartByName("ib_regs");
    },[]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                    boxWidth: 20,
                    boxHeight: 20,
                    color: "#00A066",
                    font: {
                        size: 16
                    },
                    padding: 10
                }
            },
            title: {
                display: true,
                text: '',
            },
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    const getChartByName = async (name) => {
        await getChartByNameApi(name, token).then((result)=>{
            if(name === "ib_lots"){
                setIbLotsChart(result.data)
            } else if(name === "ib_commissions"){
                setIbCommissions(result.data)
            }
        })
    }

    const labels = [];

    const ibLotsChartData = {
        labels,
        datasets: [{
            fill: true,
            backgroundColor: ({chart: {ctx}}) => {
                const bg = ctx.createLinearGradient(20, 0, 400, 500);
                bg.addColorStop(0, "rgba(0, 160, 102, 0.6)");
                bg.addColorStop(1, "rgba(201, 255, 158, 0.6)");
                return bg;
            },
            borderColor: 'rgba(0, 186, 175, 1)',
            label: ibLotsChart?.chartData?.datasets[0].label,
            data: ibLotsChart?.chartData?.datasets[0].data,
            lineTension: 0.8
        }]
    }

    const CommissionsChartData = {
        labels,
        datasets: [{
            fill: true,
            backgroundColor: ({chart: {ctx}}) => {
                const bg = ctx.createLinearGradient(20, 0, 400, 500);
                bg.addColorStop(0, "rgba(0, 160, 102, 0.6)");
                bg.addColorStop(1, "rgba(201, 255, 158, 0.6)");
                return bg;
            },
            borderColor: 'rgba(0, 186, 175, 1)',
            label: ibCommissions?.chartData?.datasets[0].label,
            data: ibCommissions?.chartData?.datasets[0].data,
            lineTension: 0.8
        }]
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_accounts')}</h1>
                <div style={{marginTop: "50px"}} className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h2>{translate('account_stats')}</h2>
                        <Button size="large" onClick={() => navigate(-1)} shape="round" style={{ marginRight: "25px" }}>{translate('back')}</Button>
                    </div>
                    <Row gutter={70}>
                        <Col md={12}>
                            <Line options={options} data={ibLotsChartData} />
                        </Col>
                        <Col md={12}>
                            <Line options={options} data={CommissionsChartData} />
                        </Col>
                    </Row>
                </div>
            </div>
        </PanelLayout>
    )
}

export default AccountStats;