import React from "react";
import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import SocialTabs from "../../../components/social/tabs";
import {useTranslation} from "react-i18next";
import {Col, Row} from "antd";
import {Link} from "react-router-dom";

const Top10 = () => {

    const { t } = useTranslation();
    const translate = t;

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('top10')}</h1>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <SocialTabs/>

                    <Row gutter={20} style={{marginTop: "15px"}}>
                        <Col lg={12}>
                            <div className={Style.Top10Box1}>
                                <div className={Style.Top10BoxInside}>
                                    <i className={Style.CrownIcon}></i>
                                    <div className={Style.TopBoxWrapper}>
                                        <h5>{translate('top10_title1')}</h5>
                                        <span>{translate('top10_slogan1')}</span>
                                    </div>
                                    <p>{translate('top10_desc1')}</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className={Style.Top10Box2}>
                                <div className={Style.Top10BoxInside}>
                                    <i className={Style.CrownIcon}></i>
                                    <div className={Style.TopBoxWrapper}>
                                        <h5>{translate('top10_title2')}</h5>
                                        <span>{translate('top10_slogan2')}</span>
                                    </div>
                                    <p>{translate('top10_desc2')}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className={Style.TraderBox}>
                        <h6>{translate('top10_box_title')}</h6>

                        <div className={Style.TradersBoxWrapper}>
                            <Row justify="center" gutter={[0, 20]}>
                                <Col span={24}>
                                    <Row justify="center">
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={`${Style.TradersBox} ${Style.TradersBoxTop}`}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/82978/topten"}>
                                                    <span>Gold Master</span>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row justify="center" gutter={[20, 20]}>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/83144/TopTen"}>
                                                    <span>FX TRADER</span>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/83342/TopTen"}>
                                                    <span>TRADER-PST</span>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/83169/TopTen"}>
                                                    <span>SAMAFX</span>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/83572/top10"}>
                                                    <span>MirzaTrader</span>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/83230/Goldenway"}>
                                                    <span>GOLDEN-WAVE</span>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row justify="center" gutter={[20, 20]}>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/83431/Top10"}>
                                                    <span>Mango-Team</span>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/83100/TopTen"}>
                                                    <span>EPIK-PRO</span>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/83106/suggest"}>
                                                    <span>101-CODE76</span>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/83117/suggest"}>
                                                    <span>101-CODE41</span>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} xs={12}>
                                            <div className={Style.TradersBox}>
                                                <Link target="_blank" to={"https://social1.wingomarkets.com:8080/portal/registration/subscription/82972/TopTen"}>
                                                    <span>ATH-TRADER</span>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <iframe
                        title="Top10"
                        id="widgetFrame"
                        style={{marginTop: "50px"}}
                        frameBorder="0"
                        height="800px"
                        width="100%"
                        src="https://social2.wingomarkets.com:8081/widgets/ratings?widgetKey=TopTen&theme=light&lang=en"
                    ></iframe>

                </div>
            </div>
        </PanelLayout>
    )
}

export default Top10;