export const currencies = [
    { label: "USDT-TRX", value: "USDT-TRX", key: "USDT-TRX" },
    { label: "USDT-ETH", value: "USDT-ETH", key: "USDT-ETH" },
    { label: "ETH", value: "ETH", key: "ETH" },
    { label: "BTC", value: "BTC", key: "BTC" },
    { label: "BCH", value: "BCH", key: "BCH" },
    { label: "LTC", value: "LTC", key: "LTC" },
    { label: "XRP", value: "XRP", key: "XRP" },
    { label: "DOGE", value: "DOGE", key: "DOGE" },
    { label: "TRX", value: "TRX", key: "TRX" },
    { label: "USDC-TRX", value: "USDC-TRX", key: "USDC-TRX" },
    { label: "USDC-ETH", value: "USDC-ETH", key: "USDC-ETH" },
    { label: "USDC-BSC", value: "USDC-BSC", key: "USDC-BSC" },
    { label: "DAI-ETH", value: "DAI-ETH", key: "DAI-ETH" },
    { label: "MANA-ETH", value: "MANA-ETH", key: "MANA-ETH" },
    { label: "DASH", value: "DASH", key: "DASH" },
];

export const leverage = [
    { label: "1:500", value: "500", key: "500" },
    { label: "1:400", value: "400", key: "400" },
    { label: "1:300", value: "300", key: "300" },
    { label: "1:200", value: "200", key: "200" },
    { label: "1:100", value: "100", key: "100" },
    { label: "1:50", value: "50", key: "50" },
    { label: "1:33", value: "33", key: "33" },
    { label: "1:10", value: "10", key: "10" }
];

export const initialBalance = [
    { label: "1000", value: "1000", key: "1000" },
    { label: "3000", value: "3000", key: "3000" },
    { label: "5000", value: "5000", key: "5000" },
    { label: "10000", value: "10000", key: "10000" },
    { label: "50000", value: "50000", key: "50000" },
    { label: "100000", value: "100000", key: "100000" }
];


export const countriesCode = [
    {label:"Afghanistan",value:"93",iso:"AF"},
    {label:"Albania",value:"355",iso:"AL"},
    {label:"Algeria",value:"213",iso:"DZ"},
    {label:"American Samoa",value:"1-684",iso:"AS"},
    {label:"Andorra",value:"376",iso:"AD"},
    {label:"Angola",value:"244",iso:"AO"},
    {label:"Anguilla",value:"1-264",iso:"AI"},
    {label:"Antarctica",value:"672",iso:"AQ"},
    {label:"Antigua and Barbuda",value:"1-268",iso:"AG"},
    {label:"Argentina",value:"54",iso:"AR"},
    {label:"Armenia",value:"374",iso:"AM"},
    {label:"Aruba",value:"297",iso:"AW"},
    {label:"Australia",value:"61",iso:"AU"},
    {label:"Austria",value:"43",iso:"AT"},
    {label:"Azerbaijan",value:"994",iso:"AZ"},
    {label:"Bahamas",value:"1-242",iso:"BS"},
    {label:"Bahrain",value:"973",iso:"BH"},
    {label:"Bangladesh",value:"880",iso:"BD"},
    {label:"Barbados",value:"1-246",iso:"BB"},
    {label:"Belarus",value:"375",iso:"BY"},
    {label:"Belgium",value:"32",iso:"BE"},
    {label:"Belize",value:"501",iso:"BZ"},
    {label:"Benin",value:"229",iso:"BJ"},
    {label:"Bermuda",value:"1-441",iso:"BM"},
    {label:"Bhutan",value:"975",iso:"BT"},
    {label:"Bolivia",value:"591",iso:"BO"},
    {label:"Bosnia and Herzegovina",value:"387",iso:"BA"},
    {label:"Botswana",value:"267",iso:"BW"},
    {label:"Brazil",value:"55",iso:"BR"},
    {label:"British Indian Ocean Territory",value:"246",iso:"IO"},
    {label:"British Virgin Islands",value:"1-284",iso:"VG"},
    {label:"Brunei",value:"673",iso:"BN"},
    {label:"Bulgaria",value:"359",iso:"BG"},
    {label:"Burkina Faso",value:"226",iso:"BF"},
    {label:"Burundi",value:"257",iso:"BI"},
    {label:"Cambodia",value:"855",iso:"KH"},
    {label:"Cameroon",value:"237",iso:"CM"},
    {label:"Canada",value:"1",iso:"CA"},
    {label:"Cape Verde",value:"238",iso:"CV"},
    {label:"Cayman Islands",value:"1-345",iso:"KY"},
    {label:"Central African Republic",value:"236",iso:"CF"},
    {label:"Chad",value:"235",iso:"TD"},
    {label:"Chile",value:"56",iso:"CL"},
    {label:"China",value:"86",iso:"CN"},
    {label:"Christmas Island",value:"61",iso:"CX"},
    {label:"Cocos Islands",value:"61",iso:"CC"},
    {label:"Colombia",value:"57",iso:"CO"},
    {label:"Comoros",value:"269",iso:"KM"},
    {label:"Cook Islands",value:"682",iso:"CK"},
    {label:"Costa Rica",value:"506",iso:"CR"},
    {label:"Croatia",value:"385",iso:"HR"},
    {label:"Cuba",value:"53",iso:"CU"},
    {label:"Curacao",value:"599",iso:"CW"},
    {label:"Cyprus",value:"357",iso:"CY"},
    {label:"Czech Republic",value:"420",iso:"CZ"},
    {label:"Democratic Republic of the Congo",value:"243",iso:"CD"},
    {label:"Denmark",value:"45",iso:"DK"},
    {label:"Djibouti",value:"253",iso:"DJ"},
    {label:"Dominica",value:"1-767",iso:"DM"},
    {label:"Dominican Republic",value:"1-809, 1-829, 1-849",iso:"DO"},
    {label:"East Timor",value:"670",iso:"TL"},
    {label:"Ecuador",value:"593",iso:"EC"},
    {label:"Egypt",value:"20",iso:"EG"},
    {label:"El Salvador",value:"503",iso:"SV"},
    {label:"Equatorial Guinea",value:"240",iso:"GQ"},
    {label:"Eritrea",value:"291",iso:"ER"},
    {label:"Estonia",value:"372",iso:"EE"},
    {label:"Ethiopia",value:"251",iso:"ET"},
    {label:"Falkland Islands",value:"500",iso:"FK"},
    {label:"Faroe Islands",value:"298",iso:"FO"},
    {label:"Fiji",value:"679",iso:"FJ"},
    {label:"Finland",value:"358",iso:"FI"},
    {label:"France",value:"33",iso:"FR"},
    {label:"French Polynesia",value:"689",iso:"PF"},
    {label:"Gabon",value:"241",iso:"GA"},
    {label:"Gambia",value:"220",iso:"GM"},
    {label:"Georgia",value:"995",iso:"GE"},
    {label:"Germany",value:"49",iso:"DE"},
    {label:"Ghana",value:"233",iso:"GH"},
    {label:"Gibraltar",value:"350",iso:"GI"},
    {label:"Greece",value:"30",iso:"GR"},
    {label:"Greenland",value:"299",iso:"GL"},
    {label:"Grenada",value:"1-473",iso:"GD"},
    {label:"Guam",value:"1-671",iso:"GU"},
    {label:"Guatemala",value:"502",iso:"GT"},
    {label:"Guernsey",value:"44-1481",iso:"GG"},
    {label:"Guinea",value:"224",iso:"GN"},
    {label:"Guinea-Bissau",value:"245",iso:"GW"},
    {label:"Guyana",value:"592",iso:"GY"},
    {label:"Haiti",value:"509",iso:"HT"},
    {label:"Honduras",value:"504",iso:"HN"},
    {label:"Hong Kong",value:"852",iso:"HK"},
    {label:"Hungary",value:"36",iso:"HU"},
    {label:"Iceland",value:"354",iso:"IS"},
    {label:"India",value:"91",iso:"IN"},
    {label:"Indonesia",value:"62",iso:"ID"},
    {label:"Iran",value:"98",iso:"IR"},
    {label:"Iraq",value:"964",iso:"IQ"},
    {label:"Ireland",value:"353",iso:"IE"},
    {label:"Isle of Man",value:"44-1624",iso:"IM"},
    {label:"Israel",value:"972",iso:"IL"},
    {label:"Italy",value:"39",iso:"IT"},
    {label:"Ivory Coast",value:"225",iso:"CI"},
    {label:"Jamaica",value:"1-876",iso:"JM"},
    {label:"Japan",value:"81",iso:"JP"},
    {label:"Jersey",value:"44-1534",iso:"JE"},
    {label:"Jordan",value:"962",iso:"JO"},
    {label:"Kazakhstan",value:"7",iso:"KZ"},
    {label:"Kenya",value:"254",iso:"KE"},
    {label:"Kiribati",value:"686",iso:"KI"},
    {label:"Kosovo",value:"383",iso:"XK"},
    {label:"Kuwait",value:"965",iso:"KW"},
    {label:"Kyrgyzstan",value:"996",iso:"KG"},
    {label:"Laos",value:"856",iso:"LA"},
    {label:"Latvia",value:"371",iso:"LV"},
    {label:"Lebanon",value:"961",iso:"LB"},
    {label:"Lesotho",value:"266",iso:"LS"},
    {label:"Liberia",value:"231",iso:"LR"},
    {label:"Libya",value:"218",iso:"LY"},
    {label:"Liechtenstein",value:"423",iso:"LI"},
    {label:"Lithuania",value:"370",iso:"LT"},
    {label:"Luxembourg",value:"352",iso:"LU"},
    {label:"Macao",value:"853",iso:"MO"},
    {label:"Macedonia",value:"389",iso:"MK"},
    {label:"Madagascar",value:"261",iso:"MG"},
    {label:"Malawi",value:"265",iso:"MW"},
    {label:"Malaysia",value:"60",iso:"MY"},
    {label:"Maldives",value:"960",iso:"MV"},
    {label:"Mali",value:"223",iso:"ML"},
    {label:"Malta",value:"356",iso:"MT"},
    {label:"Marshall Islands",value:"692",iso:"MH"},
    {label:"Mauritania",value:"222",iso:"MR"},
    {label:"Mauritius",value:"230",iso:"MU"},
    {label:"Mayotte",value:"262",iso:"YT"},
    {label:"Mexico",value:"52",iso:"MX"},
    {label:"Micronesia",value:"691",iso:"FM"},
    {label:"Moldova",value:"373",iso:"MD"},
    {label:"Monaco",value:"377",iso:"MC"},
    {label:"Mongolia",value:"976",iso:"MN"},
    {label:"Montenegro",value:"382",iso:"ME"},
    {label:"Montserrat",value:"1-664",iso:"MS"},
    {label:"Morocco",value:"212",iso:"MA"},
    {label:"Mozambique",value:"258",iso:"MZ"},
    {label:"Myanmar",value:"95",iso:"MM"},
    {label:"Namibia",value:"264",iso:"NA"},
    {label:"Nauru",value:"674",iso:"NR"},
    {label:"Nepal",value:"977",iso:"NP"},
    {label:"Netherlands",value:"31",iso:"NL"},
    {label:"Netherlands Antilles",value:"599",iso:"AN"},
    {label:"New Caledonia",value:"687",iso:"NC"},
    {label:"New Zealand",value:"64",iso:"NZ"},
    {label:"Nicaragua",value:"505",iso:"NI"},
    {label:"Niger",value:"227",iso:"NE"},
    {label:"Nigeria",value:"234",iso:"NG"},
    {label:"Niue",value:"683",iso:"NU"},
    {label:"North Korea",value:"850",iso:"KP"},
    {label:"Northern Mariana Islands",value:"1-670",iso:"MP"},
    {label:"Norway",value:"47",iso:"NO"},
    {label:"Oman",value:"968",iso:"OM"},
    {label:"Pakistan",value:"92",iso:"PK"},
    {label:"Palau",value:"680",iso:"PW"},
    {label:"Palestine",value:"970",iso:"PS"},
    {label:"Panama",value:"507",iso:"PA"},
    {label:"Papua New Guinea",value:"675",iso:"PG"},
    {label:"Paraguay",value:"595",iso:"PY"},
    {label:"Peru",value:"51",iso:"PE"},
    {label:"Philippines",value:"63",iso:"PH"},
    {label:"Pitcairn",value:"64",iso:"PN"},
    {label:"Poland",value:"48",iso:"PL"},
    {label:"Portugal",value:"351",iso:"PT"},
    {label:"Puerto Rico",value:"1-787, 1-939",iso:"PR"},
    {label:"Qatar",value:"974",iso:"QA"},
    {label:"Republic of the Congo",value:"242",iso:"CG"},
    {label:"Reunion",value:"262",iso:"RE"},
    {label:"Romania",value:"40",iso:"RO"},
    {label:"Russia",value:"7",iso:"RU"},
    {label:"Rwanda",value:"250",iso:"RW"},
    {label:"Saint Barthelemy",value:"590",iso:"BL"},
    {label:"Saint Helena",value:"290",iso:"SH"},
    {label:"Saint Kitts and Nevis",value:"1-869",iso:"KN"},
    {label:"Saint Lucia",value:"1-758",iso:"LC"},
    {label:"Saint Martin",value:"590",iso:"MF"},
    {label:"Saint Pierre and Miquelon",value:"508",iso:"PM"},
    {label:"Saint Vincent and the Grenadines",value:"1-784",iso:"VC"},
    {label:"Samoa",value:"685",iso:"WS"},
    {label:"San Marino",value:"378",iso:"SM"},
    {label:"Sao Tome and Principe",value:"239",iso:"ST"},
    {label:"Saudi Arabia",value:"966",iso:"SA"},
    {label:"Senegal",value:"221",iso:"SN"},
    {label:"Serbia",value:"381",iso:"RS"},
    {label:"Seychelles",value:"248", iso:"SC"},
    {label:"Sierra Leone",value:"232",iso:"SL"},
    {label:"Singapore",value:"65",iso:"SG"},
    {label:"Sint Maarten",value:"1-721",iso:"SX"},
    {label:"Slovakia",value:"421",iso:"SK"},
    {label:"Slovenia",value:"386",iso:"SI"},
    {label:"Solomon Islands",value:"677",iso:"SB"},
    {label:"Somalia",value:"252",iso:"SO"},
    {label:"South Africa",value:"27",iso:"ZA"},
    {label:"South Korea",value:"82",iso:"KR"},
    {label:"South Sudan",value:"211",iso:"SS"},
    {label:"Spain",value:"34",iso:"ES"},
    {label:"Sri Lanka",value:"94",iso:"LK"},
    {label:"Sudan",value:"249",iso:"SD"},
    {label:"Suriname",value:"597",iso:"SR"},
    {label:"Svalbard and Jan Mayen",value:"47",iso:"SJ"},
    {label:"Swaziland",value:"268",iso:"SZ"},
    {label:"Sweden",value:"46",iso:"SE"},
    {label:"Switzerland",value:"41",iso:"CH"},
    {label:"Syria",value:"963",iso:"SY"},
    {label:"Taiwan",value:"886",iso:"TW"},
    {label:"Tajikistan",value:"992",iso:"TJ"},
    {label:"Tanzania",value:"255",iso:"TZ"},
    {label:"Thailand",value:"66",iso:"TH"},
    {label:"Togo",value:"228",iso:"TG"},
    {label:"Tokelau",value:"690",iso:"TK"},
    {label:"Tonga",value:"676",iso:"TO"},
    {label:"Trinidad and Tobago",value:"1-868",iso:"TT"},
    {label:"Tunisia",value:"216",iso:"TN"},
    {label:"Turkey",value:"90",iso:"TR"},
    {label:"Turkmenistan",value:"993",iso:"TM"},
    {label:"Turks and Caicos Islands",value:"1-649",iso:"TC"},
    {label:"Tuvalu",value:"688",iso:"TV"},
    {label:"U.S. Virgin Islands",value:"1-340",iso:"VI"},
    {label:"Uganda",value:"256",iso:"UG"},
    {label:"Ukraine",value:"380",iso:"UA"},
    {label:"United Arab Emirates",value:"971",iso:"AE"},
    {label:"United Kingdom",value:"44",iso:"GB"},
    {label:"United States",value:"1",iso:"US"},
    {label:"Uruguay",value:"598",iso:"UY"},
    {label:"Uzbekistan",value:"998",iso:"UZ"},
    {label:"Vanuatu",value:"678",iso:"VU"},
    {label:"Vatican",value:"379",iso:"VA"},
    {label:"Venezuela",value:"58",iso:"VE"},
    {label:"Vietnam",value:"84",iso:"VN"},
    {label:"Wallis and Futuna",value:"681",iso:"WF"},
    {label:"Western Sahara",value:"212",iso:"EH"},
    {label:"Yemen",value:"967",iso:"YE"},
    {label:"Zambia",value:"260",iso:"ZM"},
    {label:"Zimbabwe",value:"263",iso:"ZW"}
];