import Style from "../../../assets/css/panel.module.css";
import {Avatar, Button, Dropdown, Modal, notification, Divider} from "antd";
import Dollar from "../../../assets/img/icon/currency-dollar-circle.svg";
import Sun from "../../../assets/img/icon/sun.svg";
import Moon from "../../../assets/img/icon/moon.svg";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import AccountManager from "../../../assets/img/icon/user-check-01.svg";
import Profile from "../../../assets/img/icon/nav/user.svg";
import Verification from "../../../assets/img/icon/nav/check-verified.svg";
import Logout from "../../../assets/img/icon/nav/log-out.svg";
import {useDispatch, useSelector} from "react-redux";
import {setContactManager, setNav, setTheme, setUserData} from "../../../redux/actions/user";
import Telegram from "../../../assets/img/icon/social/telegram.svg";
import Whatsapp from "../../../assets/img/icon/social/whatsapp.svg";
import Mail from "../../../assets/img/icon/social/mail.svg";
import {getContactManagerDetailsApi, getProfileApi} from "../../../api/api";
import Language from "../common/language";
import {useTranslation} from "react-i18next";
import Logo from "../../../assets/img/logo-alt.svg";
import { useWindowSize } from '../windowSize';
import NavIcon from "../../../assets/img/nav.svg";

const Header = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const user = useSelector((state) => state.auth?.user || null);
    const theme = useSelector((state) => state.auth?.dark || false);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const nav = useSelector((state) => state.auth?.nav);
    const contactManagerRedux = useSelector((state) => state.auth?.contactManager || {});

    const { t } = useTranslation();
    const translate = t;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [contact, setContact] = useState([]);
    const {device} = useWindowSize();

    useEffect(()=>{
        if(!Object.entries(contactManagerRedux).length){
            getContactManagerDetails()
        } else {
            setContact(contactManagerRedux)
        }

        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'offline') {
            notification.error({
                message: "Error",
                description: "No Internet Connection"
            })
        }

        if(!token){
            navigate("/login")
        }

        getProfile();
    },[]);

    const items = [
        {
            key: 0,
            label: <div className={Style.HeaderProfile}>
                <div className="d-flex align-items-center">
                    <strong>{user?.firstName} {user?.lastName}</strong>
                    {user?.isVerified ? <img style={dir === "ltr" ? {marginLeft: "5px"} : {marginRight: "5px"}} width="17" height="17" src={Verification} /> : null}
                </div>

                <span className="d-block gray-color">{user?.email}</span>
                <Divider style={{ margin: "10px 0px 0px 0px", }}/>
            </div>,
        },
        {
            key: 1,
            label: <span>{translate('account_manager')}</span>,
            icon: <img width="19" height="19" src={AccountManager} />,
            disabled: !Object.entries(contact).length ? true : false,
            onClick: () => Object.entries(contact).length ? setModal(true) : null
        },
        {
            key: 2,
            label: <Link to={"/profile"}>{translate('menu_profile')}</Link>,
            icon: <img width="19" height="19" src={Profile} />
        },
        !user?.isVerified &&
        {
            key: 3,
            label: <Link to={"/profile/verification"}>{translate('menu_verification')}</Link>,
            icon: <img width="19" height="19" src={Verification} />
        },
        {
            key: 4,
            label: <span className="red-color">{translate('menu_logout')}</span>,
            icon: <img width="19" height="19" src={Logout} />,
            onClick: ()=> handleLogOut()
        }
    ];

    const handleLogOut = async () => {
        navigate("/logout")
    }

    const getContactManagerDetails = async () => {
        await getContactManagerDetailsApi(token).then((result)=>{
            const convertedData = result.data.reduce((acc, item) => {
                acc[item.key] = item.value;
                return acc;
            }, {});
            dispatch(setContactManager(convertedData))
            setContact(convertedData)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleMobileNav = () => {
        dispatch(setNav(!nav))
    }

    const getProfile = async () => {
        await getProfileApi(token).then((result)=>{
            dispatch(setUserData(result?.data))
        }).catch((error)=>{
            console.log(error)
        })
    }

    return (
        <>
            <header className={Style.PanelHeader}>
                <div className="d-flex align-items-center justify-content-between h-100 flex-wrap">
                    <div className={Style.HeaderLinks} style={device === "mobile" || device === "tablet" ? {order: 2} : null}>
                        <Button
                            type="default"
                            className={`${Style.QuickDeposit} light-green-button d-flex align-items-center`}
                            shape="round"
                            icon={<img src={Dollar} />}
                            size={"large"}
                            onClick={() => navigate("/funds/deposit")}
                        >
                            <span className={Style.HeaderButton}>{translate('header_quick_deposit')}</span>
                        </Button>
                        <div className={Style.HeaderButtons}>
                            <Link className="dark-green-color d-inline-block" to={"/accounts/new"}>
                                {device === "mobile" ? translate('menu_live_account') : translate('header_open_live_accounts')}
                            </Link>
                            <Link className="dark-green-color d-inline-block" to={"/accounts/new"}>
                                {device === "mobile" ? translate('menu_demo_account') : translate('header_open_demo_accounts')}
                            </Link>
                        </div>
                    </div>

                    <div className={Style.UserArea} style={device === "mobile" || device === "tablet" ? {order: 1} : null}>
                        <div className={Style.MobileLogo}>
                            <img src={Logo} width={120} height={42}/>
                        </div>
                        <Button style={{backgroundColor: "transparent", pointerEvents: "none"}} onClick={()=>dispatch(setTheme(!theme))} className={`${Style.SwitchTheme} light-green-button`}>
                            {/*{theme ? <img width={25} height={25} src={Moon} /> : <img width={29} height={29} src={Sun} />}*/}
                        </Button>

                        <Language />

                        <Dropdown
                            menu={{ items }}
                        >
                            <Avatar
                                style={{
                                    backgroundColor: '#00a066',
                                    color: '#fff',
                                }}
                                size={device === "desktop" ? 50
                                    : device === "tablet" ? 40
                                        : device === "mobile" ? 30
                                            : 50}
                            >
                                {user?.firstName?.substring(0,1)}
                            </Avatar>
                        </Dropdown>


                        <span className={Style.MobileNav} onClick={()=> handleMobileNav()}>
                            <img src={NavIcon}/>
                        </span>

                    </div>
                </div>
            </header>
            <Modal
                centered
                closable={false}
                title={[
                    <div key={1} className={`${Style.AccountModalHeader} ${Style.AccountModalHeaderSmall}`}>
                        <span>{translate('contact_manager_title')}</span>
                    </div>
                ]}
                footer={[
                    <div key={1} className="d-flex justify-content-center" style={{ marginBottom: "35px" }}>
                        <Button size="large" shape="round" className="dark-green-button white-color" onClick={() => setModal(false)}>{translate('close')}</Button>
                    </div>
                ]}
                open={modal}
            >
                <div className={Style.AccountManagerModal}>
                    <p>{translate('contact_manager_desc')}</p>
                    <p style={{ marginTop: "25px" }}>{translate('full_name')}: <strong>{contact.fullName}</strong></p>
                    <ul className={`${Style.AccountManagerSocial} list-unstyled p-0`}>
                        {contact.email ? <li><img width={24} height={24} src={Mail} /> <Link to={`mailto:${contact.email}`}><span>{translate('profile_email')}</span></Link></li> : null}
                        {contact.phone ?
                            <>
                                <li><img width={24} height={24} src={Whatsapp} /> <Link to={`https://wa.me/${contact.phone}`}><span>{translate('contact_whatsapp')}</span></Link></li>
                                <li><img width={24} height={24} src={Telegram} /> <Link to={`https://t.me/${contact.phone}`}><span>{translate('contact_telegram')}</span></Link></li>
                            </>
                            : null}
                    </ul>
                </div>
            </Modal>
        </>
    )
}

export default Header;