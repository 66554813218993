import PanelLayout from "../../../layout/panel";
import {useNavigate, useParams} from "react-router-dom";
import {viewProfileMessageApi} from "../../../api/api";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Style from "../../../assets/css/panel.module.css";
import ProfileTabs from "../../../components/profile/tabs";
import {Button, Skeleton} from "antd";
import {useTranslation} from "react-i18next";

const ViewMessage = () => {

    const Router = useParams();
    const {message_id} = Router;
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        viewProfileMessage()
        document.title = `${translate('wingo')} - ${translate('menu_messages')}`;
    },[])

    const viewProfileMessage = async () => {
        await viewProfileMessageApi(message_id, token).then((result)=>{
            setMessage(result.data)
            setLoading(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_profile')}</h1>
                <ProfileTabs/>
                <h2>{translate('menu_messages')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>

                    {loading ? <Skeleton/>
                        :
                        <div>
                            <h3>{message.subject}</h3>
                            <p>{message.text}</p>
                            <div className="d-flex justify-content-end">
                                <time className="dark-green-color">{message.createdAt}</time>
                            </div>
                        </div>
                    }

                    <Button style={{marginTop: "100px"}} shape="round" onClick={()=>navigate("/profile/messages")}>{translate('message_back')}</Button>
                </div>
                </div>
        </PanelLayout>
    )
}

export default ViewMessage;