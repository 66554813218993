import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import {Button, Col, Input, message, Row, Select, Upload} from "antd";
import TextArea from "antd/es/input/TextArea";
import { InboxOutlined } from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {createNewUserTicketApi, getTicketCategoriesApi} from "../../api/api";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../layout/components/windowSize";
import Guides from "../../components/guides";
const { Dragger } = Upload;

const Ticket = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const translate = t;
    const [categories, setCategories] = useState([]);
    const [newTicket, setNewTicket] = useState({});
    const [attachment, setAttachment] = useState([]);
    const [loading, setLoading] = useState(false);
    const {device} = useWindowSize();
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        getTicketCategories();
        document.title = `${translate('wingo')} - ${translate('menu_help_dek')}`;
    },[])

    const getTicketCategories = async () => {
        await getTicketCategoriesApi(token).then((result)=>{
            const array = [];
            Object.keys(result.data).map((Key)=> (
                array.push({label: Key, value: Key})
            ))
            setCategories(array)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const createNewUserTicket = async () => {
        setLoading(true)
        const data = {
            ...newTicket,
            ...(attachment.length && {attachments: attachment})
        }
        await createNewUserTicketApi(data, token).then((result)=>{
            message.success("Created Successfully")
            setLoading(false)
            navigate(`/helpdesk/ticket/${result.data.id}`)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const uploadProps = {
        name: 'file',
        multiple: true,
        customRequest(file){
            uploadFile(file)
        },
        onChange(file) {
            file.fileList.filter((x)=>x.status = "done")
        }
    }

    const uploadFile = async ({ file, onSuccess, onError }) => {
        await getBase64(file)
            .then(res => setAttachment(prevAttachment => [...prevAttachment, { name: file.name, file: res.split('base64,').pop() }]))
            .catch(err => console.log(err))
    }

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_help_dek')}</h1>
                <Guides type="createTicket" locale={lang}/>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h3>{translate('new_ticket')}</h3>

                    <Row gutter={32} className={Style.HelpDeskForm}>
                        <Col md={12} className="ant-col-full">
                            <div className="d-md-flex">
                                <label className="text-nowrap">{translate('help_category')}</label>
                                <Select
                                    className="w-100 deposit-select"
                                    options={categories}
                                    onChange={(choice)=> setNewTicket({...newTicket, category: choice})}
                                />
                            </div>
                        </Col>
                        <Col md={12} className="ant-col-full">
                            <div className="d-md-flex flex-wrap" style={device === "mobile" ? {margin: "15px 0"} : null}>
                                <div className="d-md-flex w-100">
                                    <label className="text-nowrap">{translate('title')}</label>
                                    <div className="w-100">
                                        <Input className="deposit-select" style={{borderRadius: "25px"}} onChange={(e)=> setNewTicket({...newTicket, title: e.target.value})}/>
                                        <small className="w-100">{translate('help_max_title')}</small>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={24} className="ant-col-full">
                            <label style={{marginBottom: "10px"}}>{translate('help_describe')}</label>
                            <TextArea className="deposit-select" onChange={(e)=> setNewTicket({...newTicket, text: e.target.value})}/>
                        </Col>
                        <Col md={24} className="ant-col-full">
                            <label style={{marginTop: "12px", marginBottom: "10px"}}>{translate('help_attach')}</label>
                            <Dragger
                                {...uploadProps}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{translate('upload_title')}</p>
                                <p className="ant-upload-hint">
                                    {translate('upload_note_ticket')}
                                </p>
                            </Dragger>
                        </Col>
                    </Row>

                    <div style={{marginTop: "50px"}} className="d-flex justify-content-end">
                        <Button size="large" style={dir === "ltr" ? {marginRight: "10px"} : {marginLeft: "10px"}} shape="round" onClick={()=> navigate("/helpdesk/open-tickets")}>{translate('back')}</Button>
                        <Button size="large" loading={loading} disabled={!newTicket?.category || !newTicket?.title || !newTicket?.text} shape="round" className="dark-green-button white-color" onClick={()=>createNewUserTicket()}>{translate('submit')}</Button>
                    </div>
                </div>
            </div>
        </PanelLayout>
    )
}

export default Ticket;