import { useState, useEffect } from 'react';

export function useWindowSize() {
    const getWindowSize = () => ({
        width: window.innerWidth,
        height: window.innerHeight,
        device: window.innerWidth > 992
            ? "desktop"
            : window.innerWidth < 992 && window.innerWidth > 768
                ? "tablet"
                : window.innerWidth < 768 ? "mobile" : null
    });

    const [windowSize, setWindowSize] = useState(getWindowSize);

    const handleResize = () => {
        setWindowSize(getWindowSize());
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array means this effect runs only once on mount

    return windowSize;
}
