import PanelLayout from "../../../layout/panel";
import {getProfileMessagesApi} from "../../../api/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Style from "../../../assets/css/panel.module.css";
import ProfileTabs from "../../../components/profile/tabs";
import {Table} from "antd";
import {Link} from "react-router-dom";
import Empty from "../../../assets/img/messages/empty.svg";
import {useTranslation} from "react-i18next";

const Messages = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [empty, setEmpty] = useState(false);

    useEffect(()=>{
        getProfileMessages();
        document.title = `${translate('wingo')} - ${translate('menu_messages')}`;
    },[]);

    const getProfileMessages = async () => {
        setLoading(true)

        const data = {
        };
        await getProfileMessagesApi(data, token).then((result)=>{

         const columnsWithSearchProps = result.data.columns
             .filter((x) => (x.dataIndex = x.key) && (x.key !== "is_read_column"))
             .map((column) => ({
                 ...column,
             }));

         const antDesignTableData = result.data.rows.map((item, index) => {
             const dataObj = {};
             let id;

             item.data.forEach((entry) => {
                 if (entry.key === "id") {
                     id = entry.value;
                 }
                 if (entry.key !== "is_read_column") {
                     dataObj[entry.key] = entry.value;
                 }
                 if (entry.key.includes("subject")) {
                     dataObj[entry.key] = <Link to={`/profile/message/${id}`}>{entry.value}</Link>;
                 }
             });

             dataObj.key = index.toString();
             return dataObj;
         });

         setColumns(columnsWithSearchProps)
         setRows(antDesignTableData)
         setLoading(false)

        if(!result.data.rows.length){
            setEmpty(true)
        }

     }).catch((error)=> {
            console.log(error)
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_profile')}</h1>
                <ProfileTabs/>
                <h2>{translate('menu_messages')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h3>{translate('menu_messages')}</h3>
                    {empty ?
                        <div className={Style.MessagesEmpty}>
                            <img alt="" src={Empty}/>
                            <span>{translate('no_message')}</span>
                        </div> :
                        <Table
                            scroll={{x: 560}}
                            className={`${Style.LinksTable} ${Style.Messages}`}
                            columns={columns}
                            dataSource={rows}
                            loading={loading}
                        />
                    }
                </div>
            </div>
        </PanelLayout>
    )
}

export default Messages;